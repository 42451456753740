import React, { useState } from 'react';
import MessageSection from './messageSection';
import useGetConversations from '../../hooks/useGetConversations';
import useConversation from '../../zustand/useConversation';

const ChatBox = ({userLoggedID,userLoggedInProfilePic}) => {
  const { loading, conversations } = useGetConversations();
  const [selectedUser, setSelectedUser] = useState(null);
  const { selectedConversation, setSelectedConversation } = useConversation();
  const [conversationLoaded, setConversationLoaded] = useState(false);
  const handleUserSelect =  (user) => {
    setSelectedUser(user); // Set the selected user
    setSelectedConversation(user); // Wait for this to complete if it involves asynchronous state updates
    setConversationLoaded(true); // Update state to trigger rendering
  };

  return (
    <div className="relative w-full h-[600px] rounded-lg shadow-lg overflow-hidden border border-gray-200">
      <div className="relative flex w-full h-full bg-base-100">
        
        {/* Left Sidebar for User List */}
        <div className="w-1/3 bg-sky-500 text-white overflow-y-auto">
          <div className="p-4 border-b bg-primary-focus text-white">
            <h2 className="text-sm font-semibold">Utilisateurs</h2>
          </div>
          <ul className="p-2 space-y-2">
            {conversations.map((user) => (
              <React.Fragment key={user._id}>
                <li
                  onClick={() => handleUserSelect(user)}
                  className={`p-2 cursor-pointer rounded-lg flex items-center space-x-3 transition-colors duration-150 ${
                    selectedUser?._id === user._id ? 'bg-secondary text-secondary-content' : 'hover:bg-primary-focus'
                  }`}
                >
                  <div className="relative w-7 h-7">
                    <img
                      src={user.profilePic}
                      alt={`${user.username}'s profile`}
                      className="w-7 h-7 rounded-full object-cover"
                    />
                    <span
                      className={`absolute bottom-0 right-0 w-2 h-2 rounded-full border-2 border-primary ${
                        user.isOnline ? 'bg-lime-500' : 'bg-gray-400'
                      }`}
                    ></span>
                  </div>
                  <span className="text-xs font-medium truncate">{user.username}</span>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
        
        {/* Vertical Divider */}
        <div className="w-px bg-gray-300"></div>
        
        {/* Right Chat Area - Show MessageSection only when conversationLoaded is true */}
        {conversationLoaded && selectedUser && (
          <MessageSection
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            userId={userLoggedID} // Dynamically set userId based on selectedUser
            userLoggedInProfilePic={userLoggedInProfilePic}
          />
        )}
      </div>
    </div>
  );
};

export default ChatBox;
