import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import useConversation from '../zustand/useConversation';

const useGetUserConversations = () => {
  const [loadingConv, setLoadingConv] = useState(false);
  const { selectedConversation, setMessages } = useConversation();
  const [userConversations, setUserConversations] = useState([]);

  useEffect(() => {
    const getUserConversation = async () => {
      console.log('function called');
      setLoadingConv(true);
      try {
        const token = localStorage.getItem('token');
        console.log('We look conversations with ---> ', selectedConversation._id);
        const res = await fetch(
          `https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/messages/${selectedConversation?._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.error) {
          throw new Error(data.error);
        }

        setUserConversations(data); // Update local hook state
        setMessages(data); // Sync Zustand store
      } catch (error) {
        console.log("There is no convesation..") ; 
        toast.error(error.message);
      } finally {
        setLoadingConv(false);
      }
    };

    if (selectedConversation?._id) getUserConversation();
  }, [selectedConversation?._id, setMessages]);

  return { loadingConv, userConversations };
};

export default useGetUserConversations;
