import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import useGetUserConversations from '../../hooks/useGetUserConversations';
import useSendMessage from '../../hooks/useSendMessage';
import useConversation from '../../zustand/useConversation';
import { extractTime } from '../../utils/extractTime';

const MessageSection = ({ selectedUser, userId, userLoggedInProfilePic }) => {
  const { loadingConv, userConversations } = useGetUserConversations();
  const { loadingSendingMessage, sendMessage } = useSendMessage();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { selectedConversation } = useConversation();
  const selectedUserProfilePic = selectedConversation?.profilePic;

  useEffect(() => {
    if (userConversations && selectedUser) {
      setMessages(userConversations);
    }
  }, [userConversations, selectedUser]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && selectedUser) {
      const currentTimestamp = new Date().toISOString(); // Get current timestamp
      const newMessageObject = {
        message: newMessage,
        senderId: userId,
        receiverId: selectedUser._id,
        sender: 'me',
        createdAt: currentTimestamp, // Add local timestamp
      };

      try {
        // Send the message to the backend
        await sendMessage(newMessageObject);

        // Add the message with local timestamp to the state
        if (!loadingSendingMessage) {
        // Safely update the messages state
        setMessages((prevMessages) => {
          // Check if `prevMessages` is an array
          if (!Array.isArray(prevMessages)) {
            return [newMessageObject]; // Initialize with the new message if no previous messages exist
          }
          // Append the new message if `prevMessages` is a valid array
          return [...prevMessages, newMessageObject];
        });
        }

        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error.message);
      }
    }
  };

  return (
    <div className="flex flex-col w-2/3 h-full">
      {/* Header */}
      <div className="p-3 border-b bg-neutral text-neutral-content flex items-center">
        <h2 className="text-sm font-semibold">
          Discuter avec <span className="text-yellow-500">{selectedUser.username}</span>
        </h2>
      </div>

      {/* Chat Messages with Scroll */}
      <div className="flex-grow p-4 space-y-2 overflow-y-auto" style={{ maxHeight: 'calc(100% - 150px)' }}>
        {Array.isArray(messages) && messages.length > 0 ? (
          messages.map((msg, index) => (
            <div
              key={msg._id || `msg-${index}`}
              className={`chat ${msg.senderId === userId ? 'chat-start' : 'chat-end'}`}
            >
              <div className="chat-image avatar">
                <div className="w-5 h-5 rounded">
                  <img src={msg.senderId === userId ? userLoggedInProfilePic : selectedUserProfilePic} alt="User Avatar" />
                </div>
              </div>
              <div
                className={`chat-bubble text-xs ${
                  msg.senderId === userId ? 'chat-bubble-primary' : 'chat-bubble-accent'
                }`}
              >
                {msg.message}
                {/* Display the extracted time */}
                <div className="text-zinc-50 text-[10px] mt-1">
                  {extractTime(msg.createdAt)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 mt-10">
            <p className="text-sm">Aucun message pour le moment. Envoyez un message pour commencer la conversation !</p>
          </div>
        )}
      </div>

      {/* Message Input */}
      <div className="flex items-center p-4 border-t">
        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="input input-bordered w-full mr-2 text-sm"
        />
        <button onClick={handleSendMessage} className="p-2 bg-primary rounded-full text-white">
          {loadingSendingMessage ? <div className="loading loading-spinner"></div> : <SendIcon />}
        </button>
      </div>
    </div>
  );
};

export default MessageSection;
