import React, { useState } from 'react';
import axios from 'axios';

export default function SignupDialog({ openSignup, handleCloseSignup }) {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [userValidation, setUserValidation] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setUserValidation(null);
    try {
      const response = await axios.post(
        'https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/auth/signup',
        { username, email, password }
      );
      console.log(response.data);
      setUserValidation('Utilisateur enregistré avec succès');
      handleCloseSignup();
    } catch (error) {
      console.error('There was an error creating the user!', error);
      setError("L'inscription a échoué, l'email est déjà enregistré.");
    }
  };

  return (
    <>
      {/* Modal */}
      {openSignup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
            {/* Header */}
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-lg font-bold">Inscription</h3>
              <button
                className="btn btn-sm btn-circle btn-ghost"
                onClick={handleCloseSignup}
              >
                ✕
              </button>
            </div>

            {/* Body */}
            <form onSubmit={handleSubmit} className="p-6 space-y-4">
              <p className="text-gray-600">
                Pour vous inscrire, veuillez remplir le formulaire ci-dessous.
              </p>

              {/* Username Input */}
              <div className="form-control">
                <label htmlFor="username" className="label">
                  <span className="label-text">Nom d'utilisateur</span>
                </label>
                <input
                  id="username"
                  type="text"
                  className="input input-bordered w-full"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>

              {/* Email Input */}
              <div className="form-control">
                <label htmlFor="email" className="label">
                  <span className="label-text">Adresse e-mail</span>
                </label>
                <input
                  id="email"
                  type="email"
                  className="input input-bordered w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              {/* Password Input */}
              <div className="form-control">
                <label htmlFor="password" className="label">
                  <span className="label-text">Mot de passe</span>
                </label>
                <input
                  id="password"
                  type="password"
                  className="input input-bordered w-full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              {/* Error Message */}
              {error && (
                <div className="alert alert-error shadow-lg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                    <span>{error}</span>
                  </div>
                </div>
              )}

              {/* Success Message */}
              {userValidation && (
                <div className="alert alert-success shadow-lg">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                    <span>{userValidation}</span>
                  </div>
                </div>
              )}

              {/* Footer */}
              <div className="flex justify-end space-x-2 mt-4">
                <button
                  type="button"
                  className="btn btn-outline"
                  onClick={handleCloseSignup}
                >
                  Annuler
                </button>
                <button type="submit" className="btn btn-primary">
                  S'inscrire
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
