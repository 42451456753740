import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ username, userImageUrl }) => {
  const [isNavOpen, setIsNavOpen] = useState(false); // State to manage mobile menu
  const navigate = useNavigate();

  const pages = ['Profile', 'Tâches', 'Blog'];
  const settings = ['Profile', 'Logout'];

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  return (
    <div className="navbar bg-primary text-white shadow-lg backdrop-blur-md">
      {/* Left Section - Logo */}
      <div className="navbar-start">
        <div className="flex items-center">
          <img
            src="/navBarLogo.png"
            alt="Logo"
            className="h-12 w-auto cursor-pointer"
            onClick={() => navigate('/dashboard')}
          />
          <span
            className="hidden md:block font-mono font-bold tracking-wide ml-2 cursor-pointer"
            onClick={() => navigate('/dashboard')}
          >
            Semer Le présent 
          </span>
        </div>
      </div>

      {/* Middle Section - Links */}
      {/* <div className="navbar-left hidden md:flex">
        {pages.map((page) => (
          <a
            key={page}
            className="btn btn-ghost normal-case text-lg font-medium"
            onClick={() => navigate(`/${page.toLowerCase()}`)}
          >
            {page}
          </a>
        ))}
      </div> */}

      {/* Right Section - User Profile */}
      <div className="navbar-end mr-2">
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src={userImageUrl} alt="User Avatar" />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            {settings.map((setting) => (
              <li key={setting}>
                <a
                  className="text-white"
                  onClick={setting === 'Logout' ? handleLogout : () => navigate(`/${setting.toLowerCase()}`)}
                >
                  {setting}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Hamburger Menu for Small Screens */}
        <button
          className="btn btn-ghost btn-circle md:hidden"
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isNavOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
            />
          </svg>
        </button>
      </div>

      {/* Mobile Menu */}
      {isNavOpen && (
        <div className="navbar-center md:hidden flex flex-col space-y-2 mt-4 bg-base-200 p-4 rounded-lg shadow-lg">
          {pages.map((page) => (
            <a
              key={page}
              className="btn btn-ghost normal-case text-lg font-medium"
              onClick={() => {
                navigate(`/${page.toLowerCase()}`);
                setIsNavOpen(false); // Close menu on click
              }}
            >
              {page}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;
