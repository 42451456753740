import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const LoginPage = ({ handleClickOpenSignup }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await axios.post('https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/auth/login', { email, password });
      const { token } = response.data;
      localStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response?.data?.message || 'Une erreur est survenue lors de la connexion');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-2xl w-full max-w-md p-8"> {/* Enhanced Shadow */}
        {/* Header */}
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Bienvenue sur Semer Le Present</h1>
          {/* Logo */}
          <div className="flex justify-center mb-4">
            <img
              src="/navBarLogo.png"
              alt="Logo"
              className="h-22 h-auto w-auto"
            />
          </div>
          <div className="divider"></div>
        </div>
  
        {/* Form Section */}
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Error Message */}
          {error && (
            <div className="alert alert-error shadow-lg">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current flex-shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                <span>{error}</span>
              </div>
            </div>
          )}
  
          {/* Email Input */}
          <div className="form-control">
            <label htmlFor="email" className="label">
              <span className="label-text">Adresse e-mail</span>
            </label>
            <input
              type="email"
              id="email"
              className="input input-bordered w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              required
            />
          </div>
  
          {/* Password Input */}
          <div className="form-control">
            <label htmlFor="password" className="label">
              <span className="label-text">Mot de passe</span>
            </label>
            <input
              type="password"
              id="password"
              className="input input-bordered w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
              required
            />
          </div>
  
          {/* Submit Button */}
          <button
            type="submit"
            className={`btn btn-primary w-full ${loading ? 'loading' : ''}`}
          >
            {loading ? 'Connexion en cours...' : 'Connexion'}
          </button>
        </form>
  
        {/* Footer Section */}
        <div className="mt-4 text-sm flex justify-between">
          <a href="#" className="link">
            Mot de passe oublié ?
          </a>
          <button
            className="link link-primary"
            onClick={handleClickOpenSignup}
          >
            Inscription
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
